import { MyIcon, useLocation, get } from "@/common";
import { Button } from "antd";

function Main(props: any) {
    // const location = useLocation()
    // const pathname = props.apiUrl || location.pathname
    const dataExport = () => {
        props.nref.search.current.export({action: 'export'})
    }
    return (
        (props.type && props.type === 'ico') ? <MyIcon type="icon-daochu" title="导出" onClick={dataExport} /> : <Button onClick={dataExport}>导出</Button>
    )
}

export default Main