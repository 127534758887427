import { Tree } from "antd";

function Main(props:any){
    const treeCheck = (data:any) => {
        props.onChange?.(data)
    }

    return(
        <Tree {...props} onCheck={treeCheck} checkedKeys={props.value}/>
    )
}

export default Main